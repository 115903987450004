.actions-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px;
  margin-top: 15px;

  a {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    border-radius: 14px;
    text-decoration: none;
    transform-style: preserve-3d;
    transition: background-color 0.3s, transform 0.3s, color 0.3s;

    height: 60px;
    width: 164px;
    align-items: center;
    justify-content: center;

    &:visited {
      &.find-cleaner {
        color: var(--neutral--100);
      }
    }

    &.find-cleaner {
      background-color: var(--accent--primary-1);
      color: var(--neutral--100);

      &:hover {
        background-color: var(--secondary--color-1);
        color: var(--neutral--100);
        transform: translate3d(0, -3px, 0.01px);
      }
    }

    &:visited {
      &.find-job {
        color: var(--accent--primary-1);
      }
    }

    &.find-job {
      color: var(--accent--primary-1);
      border-width: 1px;
      border-style: solid;
      border-color: var(--accent--primary-1);

      &:hover {
        opacity: 0.76;
        transform: translate3d(0, -3px, 0.01px);
      }
    }
  }
}