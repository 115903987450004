.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-bottom: 20px;

  .copyright {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.667em;
    color: var(--neutral--600);
  }
}
