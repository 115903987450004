.container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.111em;
  }
}