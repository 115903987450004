
.header {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 24px;

  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;

    a {
      font-size: 18px;
      text-decoration: none;
      
      &:visited {
        color: var(--neutral--800);
      }

    }
  }
}
