:root {
  --neutral--600: #6e7191;
  --neutral--800: #211f54;
  --accent--primary-1: #0075ff;
  --neutral--200: #f7f9fc;
  --neutral--300: #eff0f6;
  --general--shadow-01: rgba(20, 20, 43, 0.04);
  --neutral--100: white;
  --secondary--color-1: #1d53bb;
  --secondary--color-2: #edf5ff;
  --secondary--color-3: #4b3cfb;
  --secondary--color-4: #efeeff;
  --secondary--color-5: #3f37a0;
  --neutral--700: #4a4566;
  --neutral--500: #a0a3bd;
  --neutral--400: #dcddeb;
  --system--green-400: #11845b;
  --system--blue-400: #086cd9;
  --system--blue-300: #1d88fe;
  --system--blue-200: #8fc3ff;
  --system--blue-100: #eaf4ff;
  --system--green-300: #05c168;
  --system--green-200: #7fdca4;
  --system--green-100: #def2e6;
  --system--red-400: #dc2b2b;
  --system--300: #ff5a65;
  --system--red-200: #ffbec2;
  --system--red-100: #ffeff0;
  --system--orange-400: #d5691b;
  --system--orange-300: #ff9e2c;
  --system--orange-200: #ffd19b;
  --system--orange-100: #fff3e4;
  --general--shadow-02: rgba(20, 20, 43, 0.08);
  --general--shadow-04: rgba(20, 20, 43, 0.14);
  --general--shadow-05: rgba(20, 20, 43, 0.16);
  --general--shadow-06: rgba(20, 20, 43, 0.24);
  --button-shadow--color-01: rgba(0, 117, 255, 0.1);
  --button-shadow--color-2: rgba(0, 117, 255, 0.08);
  --button-shadow--color-3: rgba(0, 117, 255, 0.26);
  --button-shadow--white-01: rgba(20, 20, 43, 0.04);
  --button-shadow--white-02: rgba(20, 20, 43, 0.06);
  --button-shadow--white-03: rgba(20, 20, 43, 0.1);
  --general--shadow-03: rgba(20, 20, 43, 0.1);
  --secondary--color-6: #ffca0e;
  --secondary--color-7: #cee5ff;
}
