.home-container {
  .slide-1 {
    margin-top: 44px;

    .text {
      padding-left: 24px;
      padding-right: 24px;

      .title {
        font-size: 40px;
        font-weight: 700;
        list-style: 1.088em;
      }

      .subtitle {
        font-size: 16px;
        color: var(--neutral--600);
        line-height: 1.667em;
      }
    }

    .image {
      img {
        width: 100%;
      }
    }
  }

  .slide-2 {
    margin-top: 80px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;

    .text {
      .title {
        font-size: 32px;
        font-weight: 700;
      }

      .subtitle {
        font-size: 16px;
        line-height: 1.677em;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      margin-top: 48px;
      row-gap: 48px;
      align-items: center;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 374px;

        img {
          width: 240px;
          margin-bottom: 20px;
        }

        .title {
          font-size: 20px;
          font-weight: 700;
          line-height: 1.286em;
        }

        .subtitle {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.667em;
        }
      }
    }

    .actions {
      justify-content: center;
      display: flex;
      margin-top: 20px;
    }
  }

  .service-types-container {
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 1.208em;
      }

      .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.667em;
        color: var(--neutral--600);
      }
    }

    .columns {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      padding-left: 24px;

      .column {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }

    .actions {
      margin-top: 40px;
    }

    .image {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      img {
        max-width: 100%;
      }
    }
  }

  .divider {
    background-color: var(--neutral--400);
    height: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .forms {
    margin-top: 40px;
    padding: 40px 24px;
    background-color: var(--neutral--200);
    display: flex;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .form {
      border: 1px solid var(--neutral--300);
      background-color: var(--neutral--100);
      box-shadow: 0 2px 6px 0 var(--general--shadow-01);
      border-radius: 16px;
      padding: 32px 56px;
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      .form-group {
        display: flex;
        flex-direction: column;

        label {
          color: var(--neutral--800);
          margin-bottom: 12px;
          font-weight: 700;
          display: block;
        }
      }
    }
  }
}
