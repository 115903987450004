@import '/src/assets/styles/variables';
@import '/src/assets/styles/fonts';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Onest', Arial, sans-serif;
  font-size: 16px;
}

.app {
  color: var(--neutral--800);

  .main-content {
    margin-top: 30px;
  }
}

.filled-button {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  border-radius: 14px;
  text-decoration: none;
  transform-style: preserve-3d;
  transition: background-color 0.3s, transform 0.3s, color 0.3s;
  border: none;
  cursor: pointer;

  height: 60px;
  width: 164px;
  align-items: center;
  justify-content: center;

  &:visited {
    color: var(--neutral--100);
  }

  background-color: var(--accent--primary-1);
  color: var(--neutral--100);

  &:hover {
    background-color: var(--secondary--color-1);
    color: var(--neutral--100);
    transform: translate3d(0, -3px, 0.01px);
  }
}

.outline-button {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  border-radius: 14px;
  text-decoration: none;
  transform-style: preserve-3d;
  transition: background-color 0.3s, transform 0.3s, color 0.3s;
  background-color: white;
  cursor: pointer;

  height: 60px;
  width: 164px;
  align-items: center;
  justify-content: center;

  &:visited {
    color: var(--accent--primary-1);
  }

  color: var(--accent--primary-1);
  border-width: 1px;
  border-style: solid;
  border-color: var(--accent--primary-1);

  &:hover {
    opacity: 0.76;
    transform: translate3d(0, -3px, 0.01px);
  }
}

.cleanly-input {
  min-height: 40px;
  padding-top: 16px;
  padding-bottom: 16px;

  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 16px;
  margin-bottom: 0;
  padding: 16px 24px;
  font-size: 18px;
  line-height: 20px;
  transition: box-shadow 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    border-color: var(--neutral--500);
    box-shadow: 0 2px 12px 0 var(--general--shadow-03);
  }

  &:focus {
    border-color: var(--accent--primary-1);
    color: var(--neutral--800);
  }
}

.cleanly-fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
