.logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  transform-style: preserve-3d;
  padding-left: 0;
  transition: transform .3s, color .3s;

  &:hover {
    transform: scale3d(.96, .96, 1.01);
  }

  .logo {
    height: 36px;
  }

  .label {
    font-size: 25px;
    font-weight: 700;
    color: var(--neutral--800);

    a {
      text-decoration: none;

      &:visited {
        color: var(--neutral--800);
      }
    }
  }
}
